import Layout from '../common/layout';
import Banner from '../components/section/banner/v3';
// import Counter from '../components/section/counter';
import CharacterSlider from '../components/section/characterSlider/v1';
import HowToMint from '../components/section/howToMint/v1';
import About from '../components/section/about/v1';
import Partner from '../components/section/partner';
import FAQ from '../components/section/faq/v3';
import Footer from "../components/section/footer/v1";


const HomeV1 = () => {
  return (
    <Layout>
      <Banner />
      {/* <Counter /> */}
      <HowToMint />
      <CharacterSlider />
      <About />
      <FAQ />
      <Partner />
      <Footer />
    </Layout>
  );
};

export default HomeV1;
