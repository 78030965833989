import styled, { css } from 'styled-components';

export const TermsPopupWrapper = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.7);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
`;

export const TermsContent = styled.div`
  background-color: #1a1a1a;
  padding: 2rem;
  border-radius: 10px;
  max-width: 600px;
  width: 90%;
  max-height: 90vh;
  overflow-y: auto;

  --sb-track-color: #232E33;
  --sb-thumb-color: #2D65F8;
  --sb-size: 5px;

  &::-webkit-scrollbar {
    width: var(--sb-size)
  }

  &::-webkit-scrollbar-track {
    background: var(--sb-track-color);
    border-radius: 3px;
  }

  &::-webkit-scrollbar-thumb {
    background: var(--sb-thumb-color);
    border-radius: 3px;
  }

  @supports not selector(::-webkit-scrollbar) {
    scrollbar-color: var(--sb-thumb-color)
                     var(--sb-track-color);
  }

  h2 {
    margin-bottom: 1rem;
    color: #ffffff;
  }

  p {
    margin-bottom: 1rem;
  }

  .modal-actions {
    display: flex;
    justify-content: space-between;
    margin-top: 1rem;

    button {
      min-height: 40px;
    }
  }
`;

export const CheckboxLabel = styled.label`
  display: flex;
  align-items: flex-start;
  margin-bottom: 1rem;
  font-size: 14px;
  color: rgba(255, 255, 255, 0.8);

  input[type="checkbox"] {
    margin-right: 10px;
    margin-top: 3px;
  }

  a {
    color: #2D65F8;
    text-decoration: underline !important;
  }
`;

export const StyledButton = styled.button`
  min-height: 40px;
  padding: 10px 20px;
  border: none;
  border-radius: 0; // Remove border-radius for squared buttons
  cursor: pointer;
  font-weight: bold;
  transition: background-color 0.3s, color 0.3s;
  text-transform: uppercase; // Add this if your global buttons use uppercase text
  font-size: 14px; // Adjust font size if needed

  ${props => props.isAccept && css`
    background-color: ${props.allChecked ? '#6BAF8D' : '#2D65F8'};
    color: #ffffff;

    &:hover {
      background-color: ${props.allChecked ? '#5A9E7C' : '#1D4ED8'};
    }

    &:disabled {
      background-color: #666;
      cursor: not-allowed;
    }
  `}

  ${props => props.isDecline && css`
    background-color: transparent; // Make the decline button transparent
    color: #ffffff;
    border: 1px solid #ffffff; // Add a white border

    &:hover {
      background-color: rgba(255, 255, 255, 0.1); // Slight white background on hover
    }
  `}
`;