import { useState, useEffect } from 'react';
import Layout from '../common/layout';
import ConnectMenu from '../components/section/connect-button';
import { getAddresses } from '../constants';
import { useWeb3Context } from '../hooks/web3';
import Footer from '../components/section/footer/v1';
import { ethers } from 'ethers';
import { BoosterNFT } from '../abi';
import axios from 'axios';
import Skeleton, { SkeletonTheme } from 'react-loading-skeleton';
import 'react-loading-skeleton/dist/skeleton.css';
import NFTCard from '../components/nftCard/nftCard';

const NFTCardSkeleton = () => (
  <div className='col-lg-3 col-md-6 mt-4'>
    <div className='itemsBox'>
      <Skeleton height={300} containerClassName="w-100" style={{ marginBottom: '1rem' }} />
      <div className='card_content'>
        <div>
          <Skeleton width={150} />
          <Skeleton width={120} />
          <Skeleton width={80} />
        </div>
      </div>
    </div>
  </div>
);

const MyCollection = () => {
  const { chainId, address, provider } = useWeb3Context();
  const addresses = getAddresses(chainId);
  const [nfts, setNfts] = useState([]);
  const [loading, setLoading] = useState(false);

  const fetchNFTData = async (tokenId) => {
    try {
      const response = await axios.get(`${process.env.REACT_APP_BACKEND_URL}/api/nft/${tokenId}`);
      return { ...response.data, tokenId };
    } catch (error) {
      console.error('Error fetching NFT data:', error);
      return null;
    }
  };

  const fetchUserNFTs = async () => {
    if (!address || !provider) return;

    setLoading(true);
    try {
      const nftContract = new ethers.Contract(addresses.BOOSTER_NFT_ADDRESS, BoosterNFT, provider);
      const balance = await nftContract.balanceOf(address);
      
      const nftPromises = [];
      for (let i = 0; i < balance; i++) {
        const tokenId = await nftContract.tokenOfOwnerByIndex(address, i);
        nftPromises.push(fetchNFTData(tokenId.toString()));
      }

      const nftData = await Promise.all(nftPromises);
      setNfts(nftData.filter(nft => nft !== null));
    } catch (error) {
      console.error('Error fetching user NFTs:', error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchUserNFTs();
  }, [address, provider, addresses.BOOSTER_NFT_ADDRESS]);

  return (
    <Layout>
      <div className='cllctnpage'>
        <div className='container'>
          <div className='row'>
            {address ? (
              loading ? (
                <SkeletonTheme baseColor="#202020" highlightColor="#444">
                  {[...Array(4)].map((_, index) => (
                    <NFTCardSkeleton key={`skeleton-${index}`} />
                  ))}
                </SkeletonTheme>
              ) : nfts.length > 0 ? (
                nfts.map((nft, index) => (
                  <div key={nft.tokenId || `nft-${index}`} className='col-lg-3 col-md-6 mt-4'>
                    <NFTCard nftData={nft} />
                  </div>
                ))
              ) : (
                <h4 key="no-items">No Items in Collection yet</h4>
              )
            ) : (
              <ConnectMenu key="connect-menu" />
            )}
          </div>
        </div>
      </div>
      <Footer />
    </Layout>
  );
};

export default MyCollection;