import styled, { createGlobalStyle, keyframes } from 'styled-components';



const holoSparkle = keyframes`
  0%, 100% {
    opacity: .75; background-position: 50% 50%; filter: brightness(1.2) contrast(1.25);
  }
  5%, 8% {
    opacity: 1; background-position: 40% 40%; filter: brightness(.8) contrast(1.2);
  }
  13%, 16% {
    opacity: .5; background-position: 50% 50%; filter: brightness(1.2) contrast(.8);
  }
  35%, 38% {
    opacity: 1; background-position: 60% 60%; filter: brightness(1) contrast(1);
  }
  55% {
    opacity: .33; background-position: 45% 45%; filter: brightness(1.2) contrast(1.25);
  }
`;

const holoGradient = keyframes`
  0%, 100% {
    opacity: 0.5;
    background-position: 50% 50%;
    filter: brightness(.5) contrast(1);
  }
  5%, 9% {
    background-position: 100% 100%;
    opacity: 1;
    filter: brightness(.75) contrast(1.25);
  }
  13%, 17% {
    background-position: 0% 0%;
    opacity: .88;
  }
  35%, 39% {
    background-position: 100% 100%;
    opacity: 1;
    filter: brightness(.5) contrast(1);
  }
  55% {
    background-position: 0% 0%;
    opacity: 1;
    filter: brightness(.75) contrast(1.25);
  }
`;

const holoCard = keyframes`
  0%, 100% {
    transform: rotateZ(0deg) rotateX(0deg) rotateY(0deg);
  }
  5%, 8% {
    transform: rotateZ(0deg) rotateX(6deg) rotateY(-20deg);
  }
  13%, 16% {
    transform: rotateZ(0deg) rotateX(-9deg) rotateY(32deg);
  }
  35%, 38% {
    transform: rotateZ(3deg) rotateX(12deg) rotateY(20deg);
  }
  55% {
    transform: rotateZ(-3deg) rotateX(-12deg) rotateY(-27deg);
  }
`;

export const GlobalStyle = createGlobalStyle`
  .card-hover-styles {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    pointer-events: none;
  }
`;

const getGradientColor = (category) => {
  switch (category) {
    case 'Common':
      return 'linear-gradient(to right, grey, #6c757d)';
    case 'Uncommon':
      return 'linear-gradient(to right, green, #17c172)';
    case 'Rare':
      return 'linear-gradient(to right, #0d6efd, #3181ff)';
    case 'Epic':
      return 'linear-gradient(to right, #5E10F5, #A949F4)';
    case 'Mythic':
      return 'linear-gradient(to right, #ff2121, #f4c749)';
    case 'Legendary':
      return 'linear-gradient(to right, #ff2121, #f1f449)';
    default:
      return 'linear-gradient(to right, grey, grey)';
  }
};

const MintSuccessCardWrapper = styled.div`
  --color1: #efb2fb;
  --color2: #acc6f8;
  
  width: clamp(200px, 61vh, 18vw);
  height: clamp(280px, 85vh, 25.2vw);
  position: relative;
  overflow: hidden;
  z-index: 10;
  touch-action: none;
  border-radius: 5% / 3.5%;
  box-shadow: 
    -5px -5px 5px -5px var(--color1), 
    5px 5px 5px -5px var(--color2), 
    -7px -7px 10px -5px transparent, 
    7px 7px 10px -5px transparent, 
    0 0 5px 0px rgba(255,255,255,0),
    0 55px 35px -20px rgba(0, 0, 0, 0.5);
  transition: transform 0.5s ease, box-shadow 0.2s ease;
  will-change: transform, filter;
  background-color: #040712;
  background-image: var(--front);
  background-size: cover;
  background-repeat: no-repeat;
  background-position: 50% 50%;
  transform-origin: center;

  &:hover {
    box-shadow: 
      -20px -20px 30px -25px var(--color1), 
      20px 20px 30px -25px var(--color2), 
      -7px -7px 10px -5px var(--color1), 
      7px 7px 10px -5px var(--color2), 
      0 0 13px 4px rgba(255,255,255,0.3),
      0 55px 35px -20px rgba(0, 0, 0, 0.5);
  }

  &:before,
  &:after {
    content: "";
    position: absolute;
    left: 0;
    right: 0;
    bottom: 0;
    top: 0;
    background-repeat: no-repeat;
    opacity: .5;
    mix-blend-mode: color-dodge;
    transition: all .33s ease;
  }

  &:before {
    background-position: 50% 50%;
    background-size: 100% 100%;
    background-image: linear-gradient(
      115deg,
      transparent 0%,
      var(--color1) 25%,
      transparent 47%,
      transparent 53%,
      var(--color2) 75%,
      transparent 100%
    );
    opacity: .5;
    filter: brightness(.5) contrast(1);
    z-index: 1;
    mix-blend-mode: screen;
  }

  &:after {
    opacity: 1;
    background-image: url("https://assets.codepen.io/13471/sparkles.gif"), 
      url(https://assets.codepen.io/13471/holo.png), 
      linear-gradient(125deg, #ff008450 15%, #fca40040 30%, #ffff0030 40%, #00ff8a20 60%, #00cfff40 70%, #cc4cfa50 85%);
    background-position: 50% 50%;
    background-size: 160%;
    background-blend-mode: overlay;
    z-index: 2;
    filter: brightness(1) contrast(1);
    transition: all .33s ease;
    mix-blend-mode: color-dodge;
    opacity: .75;
  }

  &.active:after,
  &:hover:after {
    filter: brightness(1) contrast(1);;
    opacity: 1;
  }

  &.active,
  &:hover {
    animation: none;
    transition: box-shadow 0.1s ease-out;
  }

  &.active:before,
  &:hover:before {
    animation: none;
    background-image: linear-gradient(
      110deg,
      transparent 25%,
      var(--color1) 48%,
      var(--color2) 52%,
      transparent 75%
    );
    background-position: 50% 50%;
    background-size: 250% 250%;
    opacity: .30;
    filter: brightness(.60) contrast(1.90);
    transition: none;
  }

  &.active:before,
  &:hover:before,
  &.active:after,
  &:hover:after {
    animation: none;
    transition: none;
  }

  &.animated {
    transition: none;
    animation: ${holoCard} 12s ease 0s 1;
    &:before { 
      transition: none;
      animation: ${holoGradient} 12s ease 0s 1;
    }
    &:after {
      transition: none;
      animation: ${holoSparkle} 12s ease 0s 1;
    }
  }

  .card-container {
    background-color: #121418 !important;
    height: 100%;

  }


  .card-image {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }

  .card-content {
    bottom: 0;
    left: 0;
    right: 0;
    padding: 20px;
    color: white;
    z-index: 3;
  }

  .card-name {
    font-size: 24px;
    font-weight: bold;
    margin-bottom: 10px;
  }

  .card-description {
    font-size: 14px;
    margin-bottom: 15px;
  }

  .card-external-link {
    color: #3498db;
    text-decoration: none;
    font-size: 14px;

    &:hover {
      text-decoration: underline;
    }
  }

  .card-header {
    position: relative;
    padding: 6px;
    border-bottom: 0px solid !important;
    background-color: inherit !important;  }

  .card-image-container {
    position: relative;
    width: 100%;
    padding-top: 100%; /* 1:1 Aspect Ratio */
    overflow: hidden;
    border-radius: 15px;
  }

  .card-image {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    object-fit: cover;
  }

  .card-badge {
    position: absolute;
    top: 0px;
    left: 50%;
    transform: translateX(-50%);
    background: ${({ category }) => getGradientColor(category)};
    color: white;
    padding: 6px 20px;
    border-radius: 0 0 30px 30px;
    font-size: 13px;
    font-weight: bold;
    width: 40%;
    text-align: center;
    border: solid #121418;
    border-width: 6px 3px 3px 3px;
  }

  .price-badge {
    position: absolute;
    bottom: 0px;
    left: 50%;
    transform: translateX(-50%);
    background: #1E1F28;
    color: white;
    padding: 6px 20px;
    border-radius: 30px 30px 0 0;
    font-size: 12px;
    font-weight: 700;
    width: 40%;
    text-align: center;
    border: solid #121418;
    border-width: 3px 3px 6px 3px;
  }

  .card-content {
    padding: 10px 15px 15px 15px;
    color: white;
  }

  .card-title {
    font-size: 22px;
    margin: 0 0 10px 0;
  }

  .card-subtitle {
    font-size: 16px;
    color: #aaa;
    margin: 0 0 15px 0;
  }

  .card-stats {
    display: flex;
    justify-content: space-between;
    background-color: #1E1F28;
    padding: 10px 20px 10px 20px;
    border-radius: 10px;
  }

  .stat {
    text-align: left;
  }

  .stat-value {
    font-size: 18px;
    font-weight: bold;
  }

  .stat-label {
    font-size: 14px;
    color: #aaa;
    margin-bottom: 0px;
  }

  .distribution {
    display: flex;
    align-items: center;
  }

  .distribution-dot {
    width: 8px;
    height: 8px;
    border-radius: 50%;
    margin-right: 5px;
  }

  .distribution-dot.green {
    background-color: #00ff00;
  }

  .distribution-dot.purple {
    background-color: #9000ff;
  }

`;

export default MintSuccessCardWrapper;