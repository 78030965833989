import { createGlobalStyle } from 'styled-components';

const GlobalStyles = createGlobalStyle` 

::-moz-selection {
    background: #2D65F8;
    text-shadow: none;
    color: #ffffff;
}
::selection {
    background: #2D65F8;
    text-shadow: none;
    color: #ffffff;
} 

html,
body {
    font-size: 16px; 
    vertical-align: baseline;
    font-weight: 500;
    line-height: 1.6;
    font-weight: 500;
    overflow-x: hidden; 
    background-color: #040C12 !important;
}

img {
    max-width: 100%;
    height: auto;
}

p {
    margin: 0px;
    line-height: 1.8;  
}


h1,
h2,
h3,
h4,
h5,
h6 {
    margin: 0px;
    line-height: 1.35;
    font-family: 'Bakbak One', cursive;
}

a {
    text-decoration: none !important;
    outline: none;
    transition: all .4s;
}


ul, ol {
    list-style: outside none none;
    margin: 0px;
    padding: 0px;
}

html,
body, p, a{
    font-family: 'Inter', sans-serif;
    font-size: 16px;
    color: rgba(255, 255, 255, 0.8);
} 

@media (min-width: 1400px){
    .container{
        max-width: 1200px;
        padding: 0px 20px;
    }
} 
.web3modal-modal-lightbox {
    z-index:999 !important;
}

.cllctnpage {
    color: #fff;
    padding-top: 140px;
    min-height: 600px;
    margin-bottom: 100px;
}

.faicon {
    margin-right: 10px;
    font-size: 24px;

}

.infobox {
    display: flex;
    flex-direction: row;
    justify-content: center;
    background-color: #332100;
    padding: 10px;
    border-radius: 10px;
    margin-bottom: 25px;
    margin-top:25px;
    -webkit-box-shadow:0px 0px 22px 0px rgba(255,170,0,0.25);
    -moz-box-shadow: 0px 0px 22px 0px rgba(255,170,0,0.25);
    box-shadow: 0px 0px 22px 0px rgba(255,170,0,0.25);
}

.infobanner {
        display: inline-flex;
        text-align: center;
}
@media only screen and (max-width: 767px)
    {
        .infobanner {
            display: block;
            text-align: center;  
        }
    }

.successbox {
    display: flex;
    flex-direction: row;
    justify-content: center;
    padding: 10px;
    border-radius: 10px;
    margin-bottom: 25px;
    margin-top:25px;
    -webkit-box-shadow:0px 0px 22px 0px rgba(94,156,189,0.50);
    -moz-box-shadow: 0px 0px 22px 0px rgba(94,156,189,0.50);
    box-shadow: 0px 0px 22px 0px rgba(94,156,189,0.50);
    background-color: #054965  !important;

    a {
        color:white;
        font-size: 1.25rem;
        margin-top: 0;
        margin-bottom: 0.5rem;
        font-weight: 500 !important;
        line-height: 1.2;
        font-family: 'Bakbak One',cursive;
    }

}

.infocountdown {
    margin-left:5px;
}

.itemsBox{
    position: relative;
    background: #121317;
    border-radius: 12px;
    padding: 8px;
    display: flex;
    align-items: flex-start;
    flex-direction: column;
    min-height: 425px;
    justify-content: space-between;
}
.isMuteded {
    position: absolute;
    right: 10px;
    top: 10px;
    background-color: #00F800;
    padding: 2px;
    border-radius: 4px;
    color: #000;
}
.card_img {
    margin-bottom: 15px;
}
.card_content {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
}
.card_content h4 {
    font-size: 24px;
    color: #00d3f5;
    margin-bottom: 4px;
}
.card_content p {
    font-size: 15px;
    color: #fff;
    margin-bottom: 0;
}



.paginetion_bx_prnt .pagination{    
    justify-content: center;    
    margin-top: 40px;
}
.paginetion_bx_prnt .pagination li a{        
    background-color: #384748;    
    color: aliceblue;    
    box-shadow: none !important;
    &:hover{
    color: #00d3f5;        
    }
}
.modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.7);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}

.modal-content {
  background-color: #1a1a1a;
  padding: 2rem;
  border-radius: 10px;
  max-width: 500px;
  width: 90%;
}

.modal-actions {
  display: flex;
  justify-content: space-between;
  margin-top: 1rem;
}
  
`;

export default GlobalStyles;
