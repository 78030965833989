import { Networks, DEFAULT_NETWORK } from './blockchain';

const ADDRESSES = {
  BOOSTER_NFT_ADDRESS: process.env.REACT_APP_BOOSTER_NFT_ADDRESS,
  SALE_BOX_ADDRESS: process.env.REACT_APP_SALE_BOX_ADDRESS,
  REFERRAL_ADDRESS: process.env.REACT_APP_REFERRAL_ADDRESS,
};

export const getAddresses = (networkID = DEFAULT_NETWORK) => {
  if (networkID === Networks.CHAIN_ID) return ADDRESSES;
  console.error(`Network not supported: ${networkID}`);
  throw Error("Network not supported");
};