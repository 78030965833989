export const footerLinksV1 = [
  {
    title: "Home",
    url: "/",
  },
  {
    title: "About",
    url: "#about",
  },
  {
    title: "Perks",
    url: "#perks",
  },
  {
    title: "Partner",
    url: "#partners",
  },
  {
    title: "FAQ",
    url: "#faq",
  },
];
