import React, { useEffect, useRef, useState } from 'react';
import MintSuccessCardWrapper, { GlobalStyle } from './MintSuccessCard.style';

const MintSuccessCard = ({ nftData, totalMinted, currentIndex, onNext, price }) => {
  const cardRef = useRef(null);
  const hitboxRef = useRef(null);
  const [style, setStyle] = useState('');
  const [isHovered, setIsHovered] = useState(false);
  const category = nftData.attributes.find(attr => attr.trait_type === 'Category')?.value;
  const boostPower = nftData.attributes.find(attr => attr.trait_type === 'Boosting Power')?.value;


  useEffect(() => {
    const card = cardRef.current;
    const hitbox = hitboxRef.current;
    let animationFrameId;
    let timeoutId;

    const handleMouseMove = (e) => {
      if (!card || !hitbox) return;

      const rect = hitbox.getBoundingClientRect();
      const x = e.clientX - rect.left;
      const y = e.clientY - rect.top;

      animationFrameId = requestAnimationFrame(() => {
        const cardRect = card.getBoundingClientRect();
        const centerX = cardRect.width / 2;
        const centerY = cardRect.height / 2;
        const rotateY = ((x - centerX) / cardRect.width) * 20; // Adjust rotation sensitivity
        const rotateX = ((centerY - y) / cardRect.height) * 20; // Adjust rotation sensitivity

        // Calculate values for gradient and sparkle effects
        const px = Math.abs(Math.floor(100 / cardRect.width * x) - 100);
        const py = Math.abs(Math.floor(100 / cardRect.height * y) - 100);
        const pa = (50 - px) + (50 - py);
        const lp = (50 + (px - 50) / 1.5);
        const tp = (50 + (py - 50) / 1.5);
        const px_spark = (50 + (px - 50) / 7);
        const py_spark = (50 + (py - 50) / 7);
        const p_opc = 20 + (Math.abs(pa) * 1.5);

        const grad_pos = `background-position: ${lp}% ${tp}%;`;
        const sprk_pos = `background-position: ${px_spark}% ${py_spark}%;`;
        const opc = `opacity: ${p_opc / 100};`;

        card.style.transform = `perspective(1000px) rotateX(${rotateX}deg) rotateY(${rotateY}deg)`;

        setStyle(`
          .card:hover:before { ${grad_pos} }
          .card:hover:after { ${sprk_pos} ${opc} }
        `);
      });
    };

    const handleMouseEnter = () => {
      setIsHovered(true);
      clearTimeout(timeoutId);
    };

    const handleMouseLeave = () => {
      if (!card) return;
      setIsHovered(false);
      card.style.transform = 'perspective(1000px) rotateX(0) rotateY(0)';
      card.style.transition = 'transform 1s ease-out';
      setStyle('');

      timeoutId = setTimeout(() => {
        card.classList.add('animated');
      }, 2500);
    };

    hitbox.addEventListener('mousemove', handleMouseMove);
    hitbox.addEventListener('mouseenter', handleMouseEnter);
    hitbox.addEventListener('mouseleave', handleMouseLeave);

    return () => {
      hitbox.removeEventListener('mousemove', handleMouseMove);
      hitbox.removeEventListener('mouseenter', handleMouseEnter);
      hitbox.removeEventListener('mouseleave', handleMouseLeave);
      if (animationFrameId) {
        cancelAnimationFrame(animationFrameId);
      }
      clearTimeout(timeoutId);
    };
  }, []);

  const handleClick = () => {
    if (currentIndex < totalMinted - 1) {
      onNext();
    }
  };

  return (
    <>
      <GlobalStyle />
      <style>{style}</style>
        <div
          ref={hitboxRef}
          style={{
            position: 'relative',
            width: 'calc(100% + 1px)',
            height: 'calc(100% + 1px)',
            margin: '-20px',
            padding: '20px',
          }}
          onClick={handleClick}
        >
        <MintSuccessCardWrapper ref={cardRef} className={`card ${isHovered ? 'active' : ''}`}  category={category}>
          <div className="card-container">
              <div className="card-header">
                <div className="card-image-container">
                  <img src={nftData.image} alt={nftData.name} className="card-image" />
                </div>
                <span className="card-badge">{category}</span>
                <span className="price-badge">{price ? `${price}` : 'N/A'} GAS</span>
              </div>
              <div className="card-content">
                <h2 className="card-title">{nftData.name}</h2>
                {/* <p className="card-subtitle">Owner 0xdD...C4f</p> */}
                <div className="card-stats">
                  <div className="stat">
                    <div className="stat-label">Rarity Score</div>
                    <div className="stat-value">{nftData.rarity_score}</div>
                  </div>
                  <div className="stat">
                    <div className="stat-label">Boost Power</div>
                    <div className="distribution">
                      <span className="distribution-dot green"></span>
                      <span className="stat-value">{boostPower}</span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          {/* <div className="card-hover-styles" /> */}
        </MintSuccessCardWrapper>
          {totalMinted > 1 && (
            <div style={{ width: '100%', textAlign: 'center', marginTop: '20px' }}>
              <p style={{ fontSize: '14px', color: '#aaa', display: 'inline-block', borderRadius: '20px', backgroundColor: '#1E1F28', padding: '6px 10px' }}>
                NFT {currentIndex + 1} of {totalMinted}
              </p>
            </div>
          )}
      </div>
    </>
  );
};

export default MintSuccessCard;