// src/components/tos/TermsOfServicePopup.jsx
import React, { useState, useEffect } from 'react';
import { TermsPopupWrapper, TermsContent, CheckboxLabel, StyledButton } from './TermsOfServicePopup.style';

const TermsOfServicePopup = ({ onAccept, onDecline }) => {
  const [checkboxes, setCheckboxes] = useState({
    terms: false,
    jurisdiction: false,
    risks: false,
    responsibility: false,
    volatility: false,
    impact: false,
    indemnify: false,
    privacy: false,
  });

  const [allChecked, setAllChecked] = useState(false);

  useEffect(() => {
    setAllChecked(Object.values(checkboxes).every(Boolean));
  }, [checkboxes]);

  const handleCheckboxChange = (event) => {
    setCheckboxes({
      ...checkboxes,
      [event.target.name]: event.target.checked,
    });
  };

  return (
    <TermsPopupWrapper>
      <TermsContent>
        <h2>Terms of Service</h2>
        <p>By accessing and/or using this service and/or any related dapp, I hereby represent, warrant, acknowledge and confirm that:</p>
        
        <CheckboxLabel>
          <input
            type="checkbox"
            name="terms"
            checked={checkboxes.terms}
            onChange={handleCheckboxChange}
          />
          I have read, understood and agree to be bound by the Terms of Service including the governing law and jurisdiction contained within the Terms of Service; and
        </CheckboxLabel>

        <CheckboxLabel>
          <input
            type="checkbox"
            name="jurisdiction"
            checked={checkboxes.jurisdiction}
            onChange={handleCheckboxChange}
          />
          I am not a resident, citizen, greencard holder, work permit or visa holder of or incorporated, established or located in a Prohibited Jurisdiction.
        </CheckboxLabel>

        <CheckboxLabel>
          <input
            type="checkbox"
            name="risks"
            checked={checkboxes.risks}
            onChange={handleCheckboxChange}
          />
          I acknowledge and understand that using and/or accessing AsteroNEO involves RISKS including financial risk of COMPLETE LOSS OF FUNDS. Features such as yield farming (leveraged or otherwise), lending and/or minting cryptographic tokens involve significant risks. I acknowledge, agree and understand that by using any such features, I may LOSE ALL OF MY FUNDS.
        </CheckboxLabel>

        <CheckboxLabel>
          <input
            type="checkbox"
            name="responsibility"
            checked={checkboxes.responsibility}
            onChange={handleCheckboxChange}
          />
          I am solely responsible for any decisions and actions I take while using re.al and if I lose my funds it is solely my own fault and responsibility; and
        </CheckboxLabel>

        <CheckboxLabel>
          <input
            type="checkbox"
            name="volatility"
            checked={checkboxes.volatility}
            onChange={handleCheckboxChange}
          />
          I understand and acknowledge that there are a multitude of risks of any cryptocurrency and/or cryptographic token ("Tokens") and that nothing on this site or arising out of or in connection to re.al is an invitation and/or solicitation to purchase, trade, mint, leverage, yield farm or otherwise engage in ANY Tokens. I further understand that Tokens are PURELY speculative in nature and may NOT have any inherent value or markets. Any speculation is done on my own accord and I fully understand and acknowledge that Tokens are subject to extreme volatility and I may LOSE ALL MY FUNDS.
        </CheckboxLabel>

        <CheckboxLabel>
          <input
            type="checkbox"
            name="impact"
            checked={checkboxes.impact}
            onChange={handleCheckboxChange}
          />
          Nothing done by AsteroNEO or any officers, directors and employees of AsteroNEO should be seen, assumed or understood to impact the price of any Token, including any efforts to develop and maintain the protocol and source code.
        </CheckboxLabel>

        <CheckboxLabel>
          <input
            type="checkbox"
            name="indemnify"
            checked={checkboxes.indemnify}
            onChange={handleCheckboxChange}
          />
          I hereby indemnify and hold harmless AsteroNEO, its officers, directors and employees and any group company (including but not limited to subsidiaries, holding companies, and/or affiliates) from any losses that I may incur due to my use and access of re.al.
        </CheckboxLabel>

        <CheckboxLabel>
          <input
            type="checkbox"
            name="privacy"
            checked={checkboxes.privacy}
            onChange={handleCheckboxChange}
          />
          I have read and agree to the <a href="#"> Privacy Policy</a>.
        </CheckboxLabel>

        <div className="modal-actions">
          <StyledButton 
            onClick={onAccept} 
            disabled={!allChecked} 
            isAccept={true} 
            allChecked={allChecked}
          >
            Accept
          </StyledButton>
          <StyledButton 
            onClick={onDecline} 
            isDecline={true}
          >
            Decline
          </StyledButton>
        </div>
      </TermsContent>
    </TermsPopupWrapper>
  );
};

export default TermsOfServicePopup;