import { useEffect, useState } from 'react';
import { FaTelegram } from 'react-icons/fa';
import { MdNotes } from 'react-icons/md';
import Button from '../../../../common/button';
import NavWrapper from './Header.style';
import MobileMenu from '../mobileMenu/MobileMenu';
import logo from '../../../../assets/images/logo.png';
import ConnectMenu from '../../connect-button';
import { useWeb3Context } from '../../../../hooks/web3';

const Header = () => {
  const [isMobileMenu, setMobileMenu] = useState(false);
  const handleMobileMenu = () => {
    setMobileMenu(!isMobileMenu);
  };

  useEffect(() => {
    const header = document.getElementById('navbar');
    const handleScroll = window.addEventListener('scroll', () => {
      if (window.pageYOffset > 50) {
        header.classList.add('sticky');
      } else {
        header.classList.remove('sticky');
      }
    });

    return () => {
      window.removeEventListener('sticky', handleScroll);
    };
  }, []);

  return (
    <NavWrapper className='bithu_header' id='navbar'>
      <div className='container'>
        {/* Main Menu Start */}
        <div className='bithu_menu_sect'>
          <div className='bithu_menu_left_sect'>
            <div className='logo'>
              <a href='/'>
                <img src={logo} alt='bithu nft logo' />
              </a>
            </div>
          </div>
          <div className='bithu_menu_right_sect bithu_v1_menu_right_sect'>
            <div className='bithu_menu_list'>
              <ul>
                <li>
                  <a href='/'>Home</a>
                </li>
                <li>
                  <a href='/#about'>About</a>
                </li>
                <li>
                  <a href='/#perks'>Perks</a>
                </li>
                <li>
                  <a href='/#faq'>FAQ</a>
                </li>
                <li>
                  <a href='/#partners'>Partners</a>
                </li>
                <li>
                  <a
                    href='https://www.asteroneo.com'
                    target='_blank'
                    rel='noreferrer'
                  >
                    AsteroNEO
                  </a>
                </li>
                {/* <li className="submenu">
                  <a href="# ">Pages +</a>
                  <div className="sub_menu_sect">
                    <ul className="sub_menu_list">
                      <li>
                        <a href="/">Home One</a>
                      </li>
                      <li>
                        <a href="/home-two">Home Two</a>
                      </li>
                      <li>
                        <a href="/home-three">Home Three</a>
                      </li>
                      <li>
                        <a href="/blogs">Latest Blog</a>
                      </li>
                      <li>
                        <a href="/post">Blog Details</a>
                      </li>
                    </ul>
                  </div>
                </li> */}
              </ul>
            </div>
            <div className='bithu_menu_btns'>
              <button className='menu_btn' onClick={() => handleMobileMenu()}>
                <MdNotes />
              </button>
              <a href='https://t.me/asteroneo'>
                <Button sm variant='outline' className='join_btn'>
                  <FaTelegram /> Join
                </Button>
              </a>

              <ConnectMenu />
            </div>
          </div>
        </div>
        {/* <!-- Main Menu END --> */}
        {isMobileMenu && <MobileMenu mobileMenuhandle={handleMobileMenu} />}
      </div>
    </NavWrapper>
  );
};

export default Header;
