import styled, { keyframes } from 'styled-components';
import bannerBg from '../../../../assets/images/bg/banner_bg_3.jpg';

const rotate = keyframes`
from {
  transform: rotate(0deg);
}

to {
  transform: rotate(360deg);
}
`;

const float = keyframes`
  0% {
    transform: translateY(0px);
  }
  50% {
    transform: translateY(20px);
  }
  100% {
    transform: translateY(0px);
  }
`;


const BannerStyleWrapper = styled.section`
  background: url(${bannerBg});
  width: 100%;
  background-size: cover;
  background-position: top center;
  background-repeat: no-repeat;
  padding: 165px 0px 120px 0px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;

  h1,
  h4,
  h5 {
    margin: 0;
  }

  /* particles and banner thumb */
  .banner-image-area3 {
    position: relative;
    padding: 110px 0;
    z-index: 3;

    .banner-image1,
    .banner-image2,
    .banner-image3 {
      position: absolute;
      animation: ${float} 8s ease-in-out infinite;
    }
    .banner-image1 {
      top: -35px;
      left: 50px;
      z-index: 5;
      animation-delay: 0s;
      min-width: 400px;
    }
    .banner-image2 {
      left: -100px;
      bottom: 30px;
      animation-delay: 2s;
      min-width: 350px;
    }
    .banner-image3 {
      right: 75px;
      bottom: 90px;
      z-index: 4;
      animation-delay: 4s;
      min-width: 300px;
    }

    /* particles */
    .shape_1,
    .shape_2,
    .shape_3 {
      position: absolute;
    }
    .shape_1 {
      top: 15%;
      width: 72px;
    }

    .shape_2 {
      top: 22%;
      left: 68%;
      width: 40px;
    }

    .shape_3 {
      top: 76%;
      left: 40%;
      width: 64px;
    }

    .rotated-style {
      animation: ${rotate} 30s linear infinite;
    }
  }

  .banner-conent3 {
    .banner-subtitle {
      color: #fff;
      font-size: 24px;
      margin-bottom: 20px;
    }

    .banner-title {
      color: #ffffff;
      font-size: 60px;
      line-height: 60px;
      margin-bottom: 45px;
    }

    span {
      color: #00d176;
    }

    .accent {
      color: #00d176;
      background: -webkit-linear-gradient(90deg,#80f683 14.3%,#00d176);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    }
  }

  .disableButton {
    background: grey;
    color: white;
    cursor: not-allowed;

    &:hover {
      background: grey;
      color: white;
    }
  }

  .bithu_v3_baner_buttons {
    button {
      &.disabled {
        background: grey !important ;
        color: rgba(255, 255, 255, 0.5) !important;
        cursor: not-allowed !important;
        opacity: 0.7 !important;

        &:hover {
          background: grey !important;
          color: rgba(255, 255, 255, 0.5) !important;
        }
      }
    }
  }


  .bithu_v3_timer {
    h5 {
      font-size: 18px;
      color: #fff;
    }

    .timer_1 {
      display: block;
      margin-bottom: 40px;
    }
  }

  .banner-count-inner {
    .banner-btn-area {
      margin-right: 30px;
    }

    .input-number,
    .input-number-decrement,
    .input-number-increment {
      border: none;
      height: 60px;
      user-select: none;
    }

    .input-number-decrement,
    .input-number-increment {
      display: inline-block;
      width: 60px;
      color: #fff;
      text-align: center;
      font-weight: bold;
      line-height: 60px;
      background: rgba(255, 255, 255, 0.06);
      cursor: pointer;
    }

    .input-number {
      color: #fff;
      background: transparent;
      border-right: none;
      border-top: 2px solid rgba(255, 255, 255, 0.06);
      border-bottom: 2px solid rgba(255, 255, 255, 0.06);
      width: 80px;
      padding: 0 12px;
      vertical-align: top;
      text-align: center;
      outline: none;
    }

    .input-number-increment {
      border-left: none;
    }
  }
  .banner-bottom-text {
    color: rgba(255, 255, 255, 0.8);
    font-size: 18px;
    font-family: 'Bakbak One';
    margin-top: 52px;
    line-height: 1.5;
  }

  /* mintedamount*/
  .mintedamount {
    font-family: 'Bakbak One';
    font-size: 40px !important;
    display: flex;
    justify-content: flex-start;
    flex-direction: row;
    column-gap: 10px;
    span {
      color: rgba(255, 255, 255, 0.2);
    }
  }

  /* titmer */
  .root-react-component-countdown-timer {
    line-height: 65px;
    text-align: center;
    text-transform: uppercase;
    color: #ffffff;

    .split {
      color: rgba(255, 255, 255, 0.2);
    }
    .countBox {
      font-family: 'Bakbak One';
      font-size: 40px !important;
      display: flex;
      justify-content: flex-start;
      flex-direction: row;
      column-gap: 10px;
      span {
        color: rgba(255, 255, 255, 0.2);
      }
    }
  }

  @keyframes gradient {
  0% {
    background-position: 0% 50%;
  }
  50% {
    background-position: 100% 50%;
  }
  100% {
    background-position: 0% 50%;
  }
}

  .referral-section {
  margin-top: 20px;
  form {
    display: flex;
    align-items: center;

    input {
      flex-grow: 1;
      width: 380px;
      padding: 11px 50px 11px 20px;
      color: rgba(255, 255, 255, 0.7);
      border: 1px solid rgba(255, 255, 255, 0.3);
      background: transparent;

      &:focus {
        outline: none;
      }
    }

    button {
      height: 48px;
      width: 80px;
      position: relative;
      display: flex;
      justify-content: center;
      align-items: center;
      font-size: 16px;
      color: rgba(255, 255, 255, 0.7);
      background: transparent;
      border: 1px solid rgba(255, 255, 255, 0.3);
      border-left: none;
      cursor: pointer;
      transition: all 0.3s ease;

      &:hover {
        background: rgba(255, 255, 255, 0.1);
      }
    }
  }

  .referral-count {
    margin-top: 10px;
    font-size: 14px;
    color: rgba(255, 255, 255, 0.7);
    text-transform: uppercase;
  }
}


  @media only screen and (max-width: 1199px) {
    .banner-conent3 {
      .banner-title {
        font-size: 50px;
      }
    }
    .banner-image-area3 {
      .banner-image2 {
        left: 0;
      }
    }
  }

  @media only screen and (max-width: 991px) {
    .bithu_v3_baner_sect {
      padding-bottom: 80px;
    }

    .banner-image-area3 {
      padding-top: 20px;

      .banner-image1 {
        left: 80px;
      }
    }
  }

  @media only screen and (max-width: 767px) {
    banner-bottom-text {
      text-align: center !important;
    }
    .bithu_v3_timer {
      display: flex;
      flex-direction: column;
      align-content: center;
      align-items: center;
      justify-content: center;
    }
    .banner-count-inner {
      &.d-flex {
        display: flex;
        flex-direction: column;
        flex-wrap: nowrap;
        align-content: center;
        justify-content: center;
      }
    }
    .banner-btn-area {
      margin-right: 0px;
      margin-bottom: 30px;
    }
    .banner-conent3 {
      .banner-subtitle {
        font-size: 20px;
        margin-bottom: 15px;
        text-align: center;
      }
      .banner-title {
        font-size: 30px;
        line-height: 34px;
        margin-bottom: 30px;
        text-align: center !important;
      }
    }
    .banner-image-area3 {
      .banner-image2 {
        display: none;
      }
    }
    .referral-section {
    form {
      flex-direction: column;
      align-items: stretch;

      input {
        width: 100%;
      }

      button {
        width: 100%;
        margin-top: 10px;
        border: 1px solid rgba(255, 255, 255, 0.3);
      }
    }
  }
  }




  @media only screen and (max-width: 480px) {
    .banner-image-area3 {
      .banner-image1 {
        left: 0;
        min-width: 100px;
        max-width: 200px;
        top: -100px;
      }
      .banner-image3 {
        max-width: 190px !important;
        min-width: 100px !important;
        right: 10px !important;
        bottom: 10px !important;
        z-index: 1 !important;
      }
        .banner-image2 {
          display: inherit !important;
          min-width: 100px;
          max-width: 200px;
          left: 100px !important;
          bottom: 15px !important;
          z-index: 2;
        }
    }

    .banner-count-inner {
      .banner-btn-area {
        margin-right: 0;
        margin-bottom: 20px;
      }
    }
  }
`;

export default BannerStyleWrapper;
