import { useCallback } from "react";
import useSWR from "./useSWR";

export interface AllNFTsResponse {
  results: Result[];
  total: number;
}

export interface Result {
  tokenId: string;
  name: string;
  description: string;
  external_url: string;
  image: string;
  attributes: Attribute[];
  rarity_score: string;
  rank: string;
}

export interface Attribute {
  trait_type: string;
  value: string;
  rarity_score: string;
  percentile: string;
}

export default function useAllNFTs(pageNumber: number) {
  const fetchNFTS = useCallback(async () => {
    const res = await fetch(
      "https://api.uniwpunk.com/nft/getNFT/" + pageNumber
    );
    const data: AllNFTsResponse = await res.json();

    return data;
  }, [pageNumber]);
  return useSWR("ALL_NFTS_" + pageNumber, fetchNFTS, null);
}
