import { ethers } from 'ethers';

import { SaleBoxContract } from '../abi';

export default class SaleBoxHelper {
  public contract;

  constructor(contractAddress: string, public provider: ethers.providers.Provider) {
    this.contract = new ethers.Contract(
      contractAddress,
      SaleBoxContract,
      provider
    );
  }

  public async getSaleParameters() {
    return await this.contract.getSaleParameters();
  }
  
  public async mint(signer: ethers.Signer, wallet: string, amount: number, referrer: string, value: ethers.BigNumber) {
    const contract = this.contract.connect(signer);
    const tx = await contract.mint(wallet, amount, referrer, {
      value: value
    });
    const receipt = await tx.wait();
    
    // Find the TokensMinted event in the receipt
    const tokensMintedEvent = receipt.events.find(e => e.event === 'TokensMinted');
    
    if (tokensMintedEvent) {
      const mintedIds = tokensMintedEvent.args.ids.map(id => id.toString());
      const categories = tokensMintedEvent.args.categories.map(cat => cat.toString());
      return { mintedIds, categories };
    } else {
      throw new Error('TokensMinted event not found in transaction receipt');
    }
  }

  public async isClaimedFreeMint(wallet: string) {
    return await this.contract.isClaimedFreeMint(wallet);
  }

  public async isEligibleForFreeMint(wallet: string) {
    return await this.contract.isEligibleForFreeMint(wallet);
  }

  public async tokenMintCount(address: string) {
    return await this.contract.tokenMintCount(address);
  }

  public async price() {
    return Number(await this.contract.price());
  }

  public async itemsSold() {
    return await this.contract.itemsSold();
  }

  public async items() {
    return await this.contract.items();
  }

  public async maxMintCap() {
    return await this.contract.maxMintCap();
  }

  public async startTime() {
    return await this.contract.startTime();
  }

  public async referralThreshold() {
    return await this.contract.referralThreshold();
  }

  public updateProvider(provider: ethers.providers.Provider) {
    this.provider = provider;
    this.contract = new ethers.Contract(this.contract.address, SaleBoxContract, provider);
  }
}