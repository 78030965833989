import React from 'react';
import NFTCardWrapper from './nftCard.style';

const NFTCard = ({ nftData }) => {
  const category = nftData.attributes.find(attr => attr.trait_type === 'Category')?.value;
  const boostPower = nftData.attributes.find(attr => attr.trait_type === 'Boosting Power')?.value;

  const explorerUrl = `${process.env.REACT_APP_BLOCK_EXPLORER_URL}token/${process.env.REACT_APP_BOOSTER_NFT_ADDRESS}/instance/${nftData.tokenId}`;

  return (
    <NFTCardWrapper category={category}>
      <a href={explorerUrl} target="_blank" rel="noopener noreferrer" className="card-link">
        <div className="card-container">
          <div className="card-header">
            <div className="card-image-container">
              <img src={nftData.image} alt={nftData.name} className="card-image" />
            </div>
            <span className="card-badge">{category}</span>
          </div>
          <div className="card-content">
            <h2 className="card-title">{nftData.name}</h2>
            <div className="card-stats">
              <div className="stat">
                <div className="stat-label">Rarity Score</div>
                <div className="stat-value">{nftData.rarity_score}</div>
              </div>
              <div className="stat">
                <div className="stat-label">Boost Power</div>
                <div className="distribution">
                  <span className="distribution-dot green"></span>
                  <span className="stat-value">{boostPower}</span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </a>
    </NFTCardWrapper>
  );
};

export default NFTCard;