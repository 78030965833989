const data = [
  {
    title: "What is NEONAUTS?",
    text: "NEONAUTS is the official collection of the NEO X ecosystem, the pioneering platform on the innovative ASTERONEO Blockchain.",
  },
  {
    title: "Who is the team behind it?",
    text: "Our team is mainly based in Europe, consisting of 10 members. We have been working in the blockchain sector for five years, including two skilled artists, five blockchain developers, and three business and operations experts.",
  },
  {
    title: "Why should I mint a NEONAUTS?",
    text: "Do you believe in the future of NEO X and want to join an exciting community on the chain? If yes, mint your NEONAUTS.",
  },
  {
    title: "What is an NFT?",
    text: "NFT stands for 'Non-fungible token,' a unique digital item with ownership managed by blockchain. They can be digital art or offer benefits like exclusive access to websites, events, and ownership of items. Many are excited about using them to sell digital art, and NEONAUTS aim to provide value to early supporters.",
  },
  {
    title: "What is the economy behind it?",
    text: "The goal of NEONAUTS is to create a revenue stream for the ecosystem, benefiting the community. All NEO collected from minting will be burned, and half of the proceeds are added to liquidity.",
  },
  {
    title: "What is the contract address?",
    text: "Only interact with official smart contract addresses. NEONAUTS 0x1234567890abcdef1234567890abcdef12345678, NEOBOX 0x234567890abcdef1234567890abcdef123456789.",
  },
];

export default data;
