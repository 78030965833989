import { ethers } from 'ethers';

import { Referral } from '../abi';

export default class ReferralHelper {
  public contract;

  constructor(contractAddress: string, public provider) {
    this.contract = new ethers.Contract(
      contractAddress,
      Referral,
      provider.getSigner()
    );
  }

  public async getReferrer(user: string) {
    return await this.contract.getReferrer(user);
  }

  public async isReferralContract() {
    return await this.contract.isReferralContract();
  }

  public async operator() {
    return await this.contract.operator();
  }

  public async owner() {
    return await this.contract.owner();
  }

  public async recordReferral(user: string, referrer: string) {
    return await this.contract.recordReferral(user, referrer);
  }

  public async recordReferralCommission(referrer: string, commission: ethers.BigNumber) {
    return await this.contract.recordReferralCommission(referrer, commission);
  }

  public async referralsCount(address: string) {
    return await this.contract.referralsCount(address);
  }

  public async referrers(address: string) {
    return await this.contract.referrers(address);
  }

  public async totalReferralCommissions(address: string) {
    return await this.contract.totalReferralCommissions(address);
  }

  // Note: The following methods are only accessible by the contract owner or operator
  // and should be used with caution

  public async drainBEP20Token(token: string, amount: ethers.BigNumber, to: string) {
    return await this.contract.drainBEP20Token(token, amount, to);
  }

  public async renounceOwnership() {
    return await this.contract.renounceOwnership();
  }

  public async transferOwnership(newOwner: string) {
    return await this.contract.transferOwnership(newOwner);
  }

  public async updateOperator(operator: string) {
    return await this.contract.updateOperator(operator);
  }
}