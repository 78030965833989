import type { JsonRpcProvider, Web3Provider } from '@ethersproject/providers';
import { StaticJsonRpcProvider } from '@ethersproject/providers';
import WalletConnectProvider from '@walletconnect/web3-provider';
import type { ReactElement } from 'react';
import React, { useCallback, useContext, useEffect, useMemo, useState } from 'react';
import Web3Modal from 'web3modal';
import { ethers } from 'ethers';

import { DEFAULT_NETWORK, Networks } from '../../constants';
import { switchNetwork } from '../../helpers/switch-network';
import { getMainnetURI } from './helpers';
import Cookies from 'js-cookie';
import TermsOfServicePopup from '../../components/tos/TermsOfServicePopup';

type onChainProvider = {
  connect: () => Promise<Web3Provider | undefined>;
  disconnect: () => void;
  checkWrongNetwork: () => Promise<boolean>;
  provider: JsonRpcProvider | Web3Provider;
  address: string;
  connected: boolean;
  web3Modal: Web3Modal;
  chainID: number;
  providerChainID: number;
  hasCachedProvider: () => boolean;
};

export type Web3ContextData = {
  onChainProvider: onChainProvider;
} | null;

const Web3Context = React.createContext<Web3ContextData>(null);

export const useWeb3Context = () => {
  const web3Context = useContext(Web3Context);
  if (!web3Context) {
    throw new Error('useWeb3Context() can only be used inside of <Web3ContextProvider />, please declare it at a higher level.');
  }
  const { onChainProvider } = web3Context;
  return useMemo(() => ({ ...onChainProvider }), [web3Context]);
};

export const Web3ContextProvider: React.FC<{ children: ReactElement }> = ({ children }) => {
  const [connected, setConnected] = useState(false);
  const chainID = DEFAULT_NETWORK;
  const [providerChainID, setProviderChainID] = useState(DEFAULT_NETWORK);
  const [address, setAddress] = useState('');
  const [showTosPopup, setShowTosPopup] = useState(false);

  const uri = getMainnetURI();
  const [provider, setProvider] = useState<JsonRpcProvider>(new StaticJsonRpcProvider(uri));
  
  const web3Modal = useMemo(() => new Web3Modal({
    cacheProvider: true,
    providerOptions: {
      walletconnect: {
        package: WalletConnectProvider,
        options: {
          rpc: {
            [Networks.CHAIN_ID]: uri,
          },
        },
      },
    },
  }), [uri]);

  useEffect(() => {
    const connectWalletOnPageLoad = async () => {
      if (localStorage?.getItem('isWalletConnected') === 'true') {
        try {
          await connect();
        } catch (ex) {
          console.error('Failed to connect wallet on page load:', ex);
        }
      }
    };
    connectWalletOnPageLoad();
  }, []);

  const hasCachedProvider = (): boolean => {
    return !!web3Modal.cachedProvider;
  };

  const _initListeners = useCallback((rawProvider: any) => {
    if (!rawProvider.on) {
      return;
    }

    rawProvider.on('accountsChanged', () => setTimeout(() => window.location.reload(), 1));

    rawProvider.on('chainChanged', async (chain: number) => {
      changeNetwork(chain);
    });

    rawProvider.on('network', (_newNetwork: any, oldNetwork: any) => {
      if (!oldNetwork) return;
      window.location.reload();
    });
  }, []);

  const changeNetwork = async (otherChainID: number) => {
    const network = Number(otherChainID);
    setProviderChainID(network);
    // Reinitialize provider with the new network
    const newProvider = new ethers.providers.Web3Provider(window.ethereum, 'any');
    setProvider(newProvider);
  };

  const connect = useCallback(async (): Promise<Web3Provider | undefined> => {
    try {
      const rawProvider = await web3Modal.connect();
      _initListeners(rawProvider);
      const connectedProvider = new ethers.providers.Web3Provider(rawProvider, 'any');
      const chainId = await connectedProvider.getNetwork().then((network) => Number(network.chainId));
      const connectedAddress = await connectedProvider.getSigner().getAddress();
      
      const acceptedAddress = Cookies.get('tosAccepted');
      if (acceptedAddress === connectedAddress) {
        setAddress(connectedAddress);
        setProviderChainID(chainId);
        setProvider(connectedProvider);
        setConnected(true);
        return connectedProvider;
      } else {
        setShowTosPopup(true);
        setProvider(connectedProvider);
        return undefined;
      }
    } catch (error) {
      console.error('Failed to connect:', error);
      return undefined;
    }
  }, [_initListeners, web3Modal]);
  
  const performConnection = async (connectedProvider: JsonRpcProvider | Web3Provider): Promise<void> => {
    const chainId = await connectedProvider.getNetwork().then((network) => Number(network.chainId));
    const connectedAddress = await connectedProvider.getSigner().getAddress();
  
    setAddress(connectedAddress);
    setProviderChainID(chainId);
    setProvider(connectedProvider);
    setConnected(true);
  };
  
  const handleTosAccept = async () => {
    try {
      if (!provider) {
        throw new Error('Provider not initialized');
      }
      
      const signer = provider.getSigner();
      const signerAddress = await signer.getAddress();
      
      const currentDate = new Date().toISOString();
      const message = `
      I, the user with Ethereum address ${signerAddress}, hereby acknowledge and agree to the following:
      
      1. I have read, understood, and agree to be bound by the Terms of Service for re.al, including all associated applications and services.
      2. I confirm that I am not a resident, citizen, or located in any prohibited jurisdiction as defined in the Terms of Service.
      3. I understand and accept the risks associated with using re.al, including but not limited to the potential for complete loss of funds.
      4. I acknowledge that re.al and its related software are experimental and that their use may result in financial losses.
      5. I agree to indemnify and hold harmless re.al, its officers, directors, employees, and affiliates from any losses I may incur due to my use of the platform.
      6. I have read and agree to the Privacy Policy.
      
      By signing this message, I confirm my acceptance of these terms on ${currentDate}.
      
      This signature constitutes my electronic signature and is intended to have the same force and effect as a manual signature.
          `;
      
      const hexMessage = ethers.utils.hexlify(ethers.utils.toUtf8Bytes(message));
      
      const signature = await signer.signMessage(ethers.utils.arrayify(hexMessage));
      const recoveredAddress = ethers.utils.verifyMessage(ethers.utils.toUtf8String(hexMessage), signature);
      
      if (recoveredAddress.toLowerCase() === signerAddress.toLowerCase()) {
        Cookies.set('tosAccepted', signerAddress, { expires: 365 });
        // You might want to store the full signed message and signature for record-keeping
        Cookies.set('tosSignature', signature, { expires: 365 });
        Cookies.set('tosSignedMessage', message, { expires: 365 });
        setShowTosPopup(false);
        await performConnection(provider as JsonRpcProvider | Web3Provider);
      } else {
        throw new Error('Signature verification failed');
      }
    } catch (error) {
      console.error('Failed to connect after TOS acceptance:', error);
    }
  };
  const handleTosDecline = () => {
    setShowTosPopup(false);
    disconnect();
  };

  const checkWrongNetwork = async (): Promise<boolean> => {
    if (providerChainID !== DEFAULT_NETWORK) {
      try {
        await switchNetwork();
        return true;
      } catch (error) {
        console.error("Failed to switch network:", error);
        return false;
      }
    }
    return true;
  };

  const disconnect = useCallback(() => {
    web3Modal.clearCachedProvider();
    setConnected(false);
    setAddress('');
    setProvider(new StaticJsonRpcProvider(uri));
    setTimeout(() => {
      window.location.reload();
    }, 1);
  }, [web3Modal, uri]);

  const onChainProvider = useMemo(
    () => ({
      connect,
      disconnect,
      hasCachedProvider,
      provider,
      connected,
      address,
      chainID: providerChainID, // Use providerChainID instead of chainID
      web3Modal,
      providerChainID,
      checkWrongNetwork,
    }),
    [
      connect,
      disconnect,
      hasCachedProvider,
      provider,
      connected,
      address,
      chainID,
      web3Modal,
      providerChainID,
      checkWrongNetwork,
    ]
  );

  return (
    <Web3Context.Provider value={{ onChainProvider }}>
      {children}
      {showTosPopup && (
        <TermsOfServicePopup onAccept={handleTosAccept} onDecline={handleTosDecline} />
      )}
    </Web3Context.Provider>
  );
};