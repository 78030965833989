import React from 'react';
import ReactDOM from 'react-dom/client';
import { BrowserRouter } from 'react-router-dom';
import ContextProvider from './utils/ContextProvider';
import { SnackbarProvider } from 'notistack';
import App from './app/App';
import { Web3ContextProvider } from './hooks/web3';

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <SnackbarProvider maxSnack={3}>
    <ContextProvider>
      <BrowserRouter>
        <Web3ContextProvider>
          <App />
        </Web3ContextProvider>
      </BrowserRouter>
    </ContextProvider>
  </SnackbarProvider>
);
