/* eslint-disable jsx-a11y/anchor-is-valid */
import React from "react";
import { useSearchParams } from "react-router-dom";
import Layout from "../common/layout";
import { getAddresses } from "../constants";
import useAllNFTs from "../hooks/useAllNFTs";
import { useWeb3Context } from "../hooks/web3";

export default function Explorer() {
  const { chainId } = useWeb3Context();
  const addresses = getAddresses(chainId);
  const [searchParams] = useSearchParams();
  const { data } = useAllNFTs(+searchParams.get("page") || 1);

  const nfts = data?.results || [];

  const pagesToDisplay = Math.ceil((data?.total || 0) / 12);

  console.log(pagesToDisplay);
  const array =
    pagesToDisplay === 0
      ? []
      : [...new Array(pagesToDisplay)].map((_i, index) => index + 1);

  const pages = [];

  if (array.length <= 6) {
    pages.push(...array);
  } else {
    pages.push(...array.slice(0, 3));
    pages.push("...");
    pages.push(...array.slice(-3));
  }

  const currentPage = +searchParams.get("page") || 1;
  const prev = currentPage - 1;
  const next = currentPage !== pagesToDisplay ? currentPage + 1 : currentPage;

  return (
    <>
      <Layout>
        <div className="cllctnpage">
          <div className="container">
            <div>
              <h4>Explorer</h4>
            </div>
            <div className="row explorer_ro">
              {nfts?.map((nft) => (
                <div
                  className="col-lg-3 col-lg-3_vab col-md-6 mt-4"
                  key={nft.tokenId}
                >
                  <a
                    href={
                      "https://www.oklink.com/en/ethw/assets/" +
                      addresses.BOOSTER_NFT_ADDRESS +
                      "/" +
                      nft.tokenId
                    }
                    className="itemsBox"
                    target="_blank"
                    rel="noreferrer"
                  >
                    <img
                      src={nft.image}
                      alt=""
                      className="img-fluid w-100 card_img"
                    />
                    <div className="card_content">
                      <div>
                        <h4>NEONAUTS {nft.name}</h4>
                        <p>Boosting Power:+{nft.percentage}%</p>
                        <p>Rarity Score:{nft.rarity_score}</p>
                        <p>Rank:{nft.rank}</p>
                      </div>
                      <img
                        src="/assets/img/user_img.png"
                        alt=""
                        className="user_img"
                      />
                    </div>
                  </a>
                </div>
              ))}
              {pagesToDisplay > 0 && (
                <div className="col-md-12 mt-4">
                  <div className="paginetion_bx_prnt">
                    <nav aria-label="Page navigation example">
                      <ul className="pagination">
                        <li className="page-item">
                          <a
                            className="page-link"
                            href={`?page=${prev || 1}`}
                            aria-label="Previous"
                          >
                            <span aria-hidden="true">&laquo;</span>
                            <span className="sr-only"></span>
                          </a>
                        </li>
                        {pages.map((page) => (
                          <li
                            className={`page-item ${
                              page === currentPage ? "active" : ""
                            }`}
                            key={page}
                          >
                            {page === "..." ? (
                              <a className="page-link">{page}</a>
                            ) : (
                              <a className="page-link" href={`?page=${page}`}>
                                {page}
                              </a>
                            )}
                          </li>
                        ))}
                        <li className="page-item">
                          <a
                            className="page-link"
                            href={`?page=${next}`}
                            aria-label="Next"
                          >
                            <span aria-hidden="true">&raquo;</span>
                            <span className="sr-only"></span>
                          </a>
                        </li>
                      </ul>
                    </nav>
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>
      </Layout>
    </>
  );
}
