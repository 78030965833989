import { Routes, Route } from 'react-router-dom';
import HomeV1 from '../pages/homeV1';
import { useWeb3Context } from '../hooks/web3';
import { useEffect } from 'react';
import MyCollection from '../pages/myCollection';
import GlobalStyles from '../assets/styles/GlobalStyles';
import Header from '../components/section/header/v1/Header';
import Explorer from '../pages/Explorer';

function App() {
  const { connect, hasCachedProvider } = useWeb3Context();

  useEffect(() => {
    if (hasCachedProvider()) {
      connect();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      <GlobalStyles />
      <Header />
      <Routes>
        <Route path='/' element={<HomeV1 />} exact />
        <Route path='/my-collection' element={<MyCollection />} />
        <Route path='/explorer' element={<Explorer />} />
      </Routes>
    </>
  );
}

export default App;
