import aboutCardIcon1 from "../../images/icon/aboutus_card_icon1.svg"
import aboutCardIcon2 from "../../images/icon/aboutus_card_icon2.svg"
import aboutCardIcon3 from "../../images/icon/aboutus_card_icon3.svg"

 const data = {
    aboutDescription1: 'NEONAUTS are a collection of NFTs celebrating the launch of the NEO X chain, marking a significant milestone as the first NFTs on this platform. By immortalizing 1,000 NEONAUTS on the NEO X blockchain, this collection aims to build an engaged community within the ASTERONEO system.',
    aboutDescription2: 'These NFTs offer more than just digital art; they provide benefits like boosts across the ASTERONEO ecosystem. NEONAUTS owners will be valued as important community members, gaining exclusive access to future updates and helping shape the NEO X chain s future.',
    aboutDescription3: 'Join the NEONAUTS community and be part of this exciting new journey!',
    aboutInfo: [
        {
            icon: aboutCardIcon1,
            title: "Airdrop Points",
            text: "Hold and use NEONAUTS to earn community points for the planned platform token airdrops. The more points you collect, the more tokens you can receive later."
        },
        {
            icon: aboutCardIcon2,
            title: "Boosting Rewards",
            text: 'Lock NEONAUTS in the farm to increase your yield. This simple strategy ensures you maximize profits and make the most of your farming efforts. '
        },
        {
            icon: aboutCardIcon3,
            title: "Royalty Earning",
            text: 'Owning NEONAUTS means you get a share of the ecosystem\'s revenues. Enjoy the benefits and watch your earnings grow as the platform succeeds.'
        }
    ]
}

export default data;