import {
  FaTwitter,
  FaInstagram,
  FaTelegramPlane,
} from "react-icons/fa";

// import openseaIcon from "../images/icon/opensea.svg";
import mediumIcon from "../images/icon/med.svg";

const data = [
  // {
  //   thumb: openseaIcon,
  //   url: "#",
  // },
  {
    icon: <FaTwitter />,
    url: "https://twitter.com/asteroneo",
  },
  // {
  //   icon: <FaInstagram />,
  //   url: "#",
  // },
  {
    icon: <FaTelegramPlane />,
    url: "https://t.me/asteroneo",
  },
  {
    thumb: mediumIcon,
    url: "https://medium.com/asteroneo",
  },
];

export default data;
