import styled from 'styled-components';

const getGradientColor = (category) => {
  switch (category) {
    case 'Common':
      return 'linear-gradient(to right, grey, #6c757d)';
    case 'Uncommon':
      return 'linear-gradient(to right, green, #17c172)';
    case 'Rare':
      return 'linear-gradient(to right, #0d6efd, #3181ff)';
    case 'Epic':
      return 'linear-gradient(to right, #5E10F5, #A949F4)';
    case 'Mythic':
      return 'linear-gradient(to right, #ff2121, #dc3545)';
    case 'Legendary':
      return 'linear-gradient(to right, #ff2121, #f1f449)';
    default:
      return 'linear-gradient(to right, grey, grey)';
  }
};

const NFTCardWrapper = styled.div`
  width: 100%;
  background-color: #121418;
  border-radius: 15px;
  overflow: hidden;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  transition: transform 0.3s ease, box-shadow 0.3s ease;

  &:hover {
    transform: translateY(-5px);
    box-shadow: 0 6px 12px rgba(0, 0, 0, 0.15);
  }

  .card-container {
    background-color: #121418;
  }

  .card-header {
    position: relative;
    padding: 6px;
  }

  .card-image-container {
    position: relative;
    width: 100%;
    padding-top: 100%;
    overflow: hidden;
    border-radius: 15px;
  }

  .card-image {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    object-fit: cover;
  }

  .card-badge {
    position: absolute;
    top: 0px;
    left: 50%;
    transform: translateX(-50%);
    background: ${({ category }) => getGradientColor(category)};
    color: white;
    padding: 6px 20px;
    border-radius: 0 0 30px 30px;
    font-size: 13px;
    font-weight: bold;
    width: 40%;
    text-align: center;
    border: solid #121418;
    border-width: 6px 3px 3px 3px;
  }

  .price-badge {
    position: absolute;
    bottom: 0px;
    left: 50%;
    transform: translateX(-50%);
    background: #1E1F28;
    color: white;
    padding: 6px 20px;
    border-radius: 30px 30px 0 0;
    font-size: 12px;
    font-weight: 700;
    width: 40%;
    text-align: center;
    border: solid #121418;
    border-width: 3px 3px 6px 3px;
  }

  .card-content {
    padding: 10px 15px 15px 15px;
    color: white;
  }

  .card-title {
    font-size: 18px;
    margin: 0 0 10px 0;
  }

  .card-stats {
    display: flex;
    justify-content: space-between;
    background-color: #1E1F28;
    padding: 10px 20px 10px 20px;
    border-radius: 10px;
  }

  .stat {
    text-align: left;
  }

  .stat-value {
    font-size: 16px;
    font-weight: bold;
  }

  .stat-label {
    font-size: 12px;
    color: #aaa;
    margin-bottom: 0px;
  }

  .distribution {
    display: flex;
    align-items: center;
  }

  .distribution-dot {
    width: 8px;
    height: 8px;
    border-radius: 50%;
    margin-right: 5px;
  }

  .distribution-dot.green {
    background-color: #00ff00;
  }

  .distribution-dot.purple {
    background-color: #9000ff;
  }
`;

export default NFTCardWrapper;