import { useState, useEffect } from "react";
import { useLocalStorage } from "./useLocalStorage";

export default function useSWR<T>(
  key: string,
  apiCall: () => Promise<T>, // Provide UseCallback API call
  defaultValue: T
) {
  const [data, setData] = useLocalStorage<T>(key, defaultValue);
  const [error, setError] = useState<any>(false);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    const status = { cancelled: false };
    setLoading(true);
    setError(null);
    (async () => {
      try {
        const data = await apiCall();
        if (!status.cancelled) {
          setData(data);
        }
      } catch (e) {
        if (!status.cancelled) {
          setError(e);
        }
      }
      if (!status.cancelled) {
        setLoading(false);
      }
    })();

    return () => {
      status.cancelled = true;
    };
  }, [apiCall, setData]);

  return {
    data,
    error,
    loading,
  };
}
