import { useEffect, useMemo, useState, useCallback } from 'react';
import { useLocation } from 'react-router-dom';
import Cookies from 'js-cookie';
import { encodeAddress, decodeAddress } from '../../../../utils/referralUtils';
import Countdown from 'react-countdown';
import Button from '../../../../common/button';
import Particle from '../../../../common/particle/v2';
import bannerThumb1 from '../../../../assets/images/banner/Item_1.png';
import bannerThumb2 from '../../../../assets/images/banner/Item_2.png';
import bannerThumb3 from '../../../../assets/images/banner/Item_3.png';
import BannerStyleWrapper from './Banner.style';
import SaleBoxHelper from '../../../../helpers/saleboxhelper';
import ReferralHelper from '../../../../helpers/referralhelpers';
import { getAddresses } from '../../../../constants';
import { useWeb3Context } from '../../../../hooks/web3';
import { useDataApi } from '../../../../hooks/useApi';
import ConnectMenu from '../../connect-button';
import { useSnackbar } from 'notistack';
import { ethers } from 'ethers';
import Confetti from 'react-confetti';
import useInterval from '../../../../hooks/useInterval';
import Skeleton, { SkeletonTheme } from 'react-loading-skeleton';
import 'react-loading-skeleton/dist/skeleton.css';
import { motion, AnimatePresence } from 'framer-motion/dist/framer-motion';
import MintSuccessCard from '../../../mintSuccessCard/MintSuccessCard';
import axios from 'axios';

const MintSuccessAnimation = ({ isVisible, onClose, nftData, totalMinted, currentIndex, onNext, price }) => {
  return (
    <AnimatePresence>
      {isVisible && (
        <motion.div
          key="mint-success-overlay"
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          exit={{ opacity: 0 }}
          transition={{ duration: 0.3 }}
          style={{
            position: 'fixed',
            top: 0,
            left: 0,
            right: 0,
            bottom: 0,
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            background: 'rgba(0, 0, 0, 0.8)',
            zIndex: 9998,
          }}
          onClick={onClose}
        >
          <Confetti width={window.innerWidth} height={window.innerHeight} />
          <motion.div
            initial={{ y: '100%', opacity: 0, scale: 0.8 }}
            animate={{ y: 0, opacity: 1, scale: 1 }}
            exit={{ y: 0, opacity: 0, scale: 0.8 }}
            transition={{ duration: 0.3 }}
            onClick={(e) => e.stopPropagation()}
          >
            <MintSuccessCard
              nftData={nftData}
              totalMinted={totalMinted}
              currentIndex={currentIndex}
              onNext={onNext}
              price={price}
            />
          </motion.div>
        </motion.div>
      )}
    </AnimatePresence>
  );
};

const Banner = () => {
  const { chainId, provider, address } = useWeb3Context();
  const [count, setCount] = useState(1);
  const [loading, setLoading] = useState(false);
  const [saleBoxHelper, setSaleBoxHelper] = useState(null);
  const [saleParameters, setSaleParameters] = useState(null);
  const [balance, setBalance] = useState(0);
  const [itemsSold, setItemsSold] = useState(0);
  const location = useLocation();
  const [referrer, setReferrer] = useState('');
  const [referralHelper, setReferralHelper] = useState(null);
  const [referralCount, setReferralCount] = useState(0);
  const [maxCount, setMaxCount] = useState(1);
  const [isFreeMint, setIsFreeMint] = useState(false);
  const [isClaimedFreeMint, setIsClaimedFreeMint] = useState(false);
  const [currentTime, setCurrentTime] = useState(new Date().getTime());
  const [isCountdownComplete, setIsCountdownComplete] = useState(false);
  const [showMintAnimation, setShowMintAnimation] = useState(false);
  const [isExiting, setIsExiting] = useState(false);
  const [mintedNFTs, setMintedNFTs] = useState([]);
  const [currentNFTIndex, setCurrentNFTIndex] = useState(0);

  const addresses = useMemo(() => getAddresses(chainId), [chainId]);
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();

  const closeMintAnimation = useCallback(() => {
    setIsExiting(true);
  }, []);


  useEffect(() => {
    let exitTimer;
    let unmountTimer;

    if (showMintAnimation && !isExiting) {
      exitTimer = setTimeout(() => {
        setIsExiting(true);
      }, 100000);
    }

    if (isExiting) {
      unmountTimer = setTimeout(() => {
        setShowMintAnimation(false);
        setIsExiting(false);
      }, 300);
    }

    return () => {
      clearTimeout(exitTimer);
      clearTimeout(unmountTimer);
    };
  }, [showMintAnimation, isExiting]);

  useEffect(() => {
    if (addresses.SALE_BOX_ADDRESS) {
      const saleHelper = new SaleBoxHelper(addresses.SALE_BOX_ADDRESS, provider);
      setSaleBoxHelper(saleHelper);
    }
    
    if (addresses.REFERRAL_ADDRESS && address) {
      const referralHelper = new ReferralHelper(addresses.REFERRAL_ADDRESS, provider);
      setReferralHelper(referralHelper);
    }
  }, [provider, addresses.SALE_BOX_ADDRESS, addresses.REFERRAL_ADDRESS, address, chainId]);

  const { data: saleParams } = useDataApi(
    () => saleBoxHelper?.getSaleParameters(),
    [saleBoxHelper]
  );

  useEffect(() => {
    if (saleParams) {
      setSaleParameters({
        itemsSold: saleParams[0],
        items: saleParams[1],
        price: ethers.utils.formatEther(saleParams[2]),
        startTime: saleParams[3],
        maxMintCap: saleParams[4],
        referralThreshold: saleParams[5],
      });
    }
  }, [saleParams]);

  const { data: tokenMintCount } = useDataApi(
    () => saleBoxHelper?.tokenMintCount(address),
    [saleBoxHelper, address]
  );

  useEffect(() => {
    const params = new URLSearchParams(location.search);
    const encodedAddress = params.get('ref');
    if (encodedAddress) {
      const decodedAddress = decodeAddress(encodedAddress);
      if (decodedAddress !== ethers.constants.AddressZero) {
        Cookies.set('referrer', decodedAddress, { expires: 7 }); // Cookie expires in 7 days
        setReferrer(decodedAddress);
      }
    } else {
      const savedReferrer = Cookies.get('referrer');
      if (savedReferrer) {
        setReferrer(savedReferrer);
      }
    }
  }, [location]);

  // Separate useEffect for fetching itemsSold
  useEffect(() => {
    const fetchItemsSold = async () => {
      if (saleBoxHelper) {
        try {
          const saleParams = await saleBoxHelper.getSaleParameters();
          setItemsSold(Number(saleParams[0]));
        } catch (error) {
          console.error("Error fetching items sold:", error);
        }
      }
    };

    fetchItemsSold();
    const intervalId = setInterval(fetchItemsSold, 5000);

    return () => clearInterval(intervalId);
  }, [saleBoxHelper]);

  // Modified useEffect for wallet-dependent operations
  useEffect(() => {
    const fetchWalletData = async () => {
      if (provider && address && referralHelper && saleBoxHelper && saleParameters) {
        try {
          // Fetch balance
          const balance = await provider.getBalance(address);
          setBalance(ethers.utils.formatEther(balance));

          // Fetch referral count
          const count = await referralHelper.referralsCount(address);
          setReferralCount(Number(count));

          // Check free mint eligibility
          const claimed = await saleBoxHelper.isClaimedFreeMint(address);
          setIsClaimedFreeMint(claimed);
          setIsFreeMint(Number(count) >= Number(saleParameters.referralThreshold) && !claimed);
        } catch (error) {
          console.error("Error fetching wallet data:", error);
        }
      }
    };

    fetchWalletData();
  }, [provider, address, referralHelper, saleBoxHelper, saleParameters]);

  useEffect(() => {
    if (saleParameters && tokenMintCount) {
      setMaxCount(Number(saleParameters.maxMintCap) - Number(tokenMintCount));
    }
  }, [saleParameters, tokenMintCount]);

  const renderer = ({ days, hours, minutes, seconds, completed }) => {
    if (completed) {
      return null;
    } else {
      return (
        <div className='root-react-component-countdown-timer' id='countdownwrap'>
          <div className='displayedTime'>
            <div className='countBox left'>
              {days} D
              <span className='split'>:</span>
              {hours} H
              <span className='split'>:</span>
              {minutes} M
              <span className='split'>:</span>
              {seconds} S
            </div>
          </div>
        </div>
      );
    }
  };

  const fetchItemsSold = useCallback(async () => {
    if (saleBoxHelper) {
      const saleParams = await saleBoxHelper.getSaleParameters();
      setItemsSold(Number(saleParams[0]));
    }
  }, [saleBoxHelper]);

  const isSaleStarted = useMemo(() => {
    if (!saleParameters) return false;
    return currentTime >= saleParameters.startTime * 1000;
  }, [saleParameters, currentTime]);

  useEffect(() => {
    if (saleParameters && !isSaleStarted) {
      const timer = setTimeout(() => {
        setCurrentTime(new Date().getTime());
        if (new Date().getTime() >= saleParameters.startTime * 1000) {
          setIsCountdownComplete(true);
        }
      }, 1000);
      return () => clearTimeout(timer);
    }
  }, [saleParameters, currentTime, isSaleStarted]);

  useEffect(() => {
    if (isCountdownComplete) {
      setIsCountdownComplete(false);
    }
  }, [isCountdownComplete]);

  const fetchNFTData = async (tokenId) => {
    try {
      const response = await axios.get(`${process.env.REACT_APP_BACKEND_URL}/api/nft/${tokenId}`);
      return response.data;
    } catch (error) {
      console.error('Error fetching NFT data:', error);
      return null;
    }
  };

  const onMint = async () => {
    if (!saleBoxHelper || !saleParameters || !provider) {
      enqueueSnackbar('Sale box helper not initialized, sale parameters not loaded, or provider not available', { variant: 'error' });
      return;
    }

    // Ensure the provider is updated before checking the chain ID
    const signer = provider.getSigner();
    const network = await signer.provider.getNetwork();
    const currentChainId = network.chainId;

    // Check if the current chain ID matches the one in .env.testnet
    const correctChainId = parseInt(process.env.REACT_APP_CHAIN_ID || '0', 10);
    if (currentChainId !== correctChainId) {
      enqueueSnackbar(`Please switch to ${process.env.REACT_APP_CHAIN_NAME} network to mint`, { 
        variant: 'warning',
        autoHideDuration: 5000,
      });
      return;
    }

    const totalPrice = isFreeMint ? ethers.constants.Zero : ethers.utils.parseEther(
      (count * Number(saleParameters.price)).toString()
    );
    if (!isFreeMint && totalPrice.gt(ethers.utils.parseEther(balance))) {
      enqueueSnackbar('Insufficient balance', { variant: 'error' });
      return;
    }

    enqueueSnackbar('Minting is in Progress', {
      variant: 'info',
      key: 'mint',
      persist: true,
    });
    setLoading(true);
    try {
      saleBoxHelper.updateProvider(provider);
      const referrerAddress = referrer && referrer !== address ? referrer : ethers.constants.AddressZero;
      const { mintedIds, categories } = await saleBoxHelper.mint(signer, address, count, referrerAddress, totalPrice);

      console.log('Minted Token IDs:', mintedIds);
      console.log('Categories:', categories);
  
      if (mintedIds.length > 0) {
        const nftDataPromises = mintedIds.map(tokenId => fetchNFTData(tokenId));
        const nftDataResults = await Promise.all(nftDataPromises);
  
        const validNFTData = nftDataResults.filter(data => data !== null && data.attributes);
        if (validNFTData.length > 0) {
          setMintedNFTs(validNFTData);
          setCurrentNFTIndex(0);
          setShowMintAnimation(true);
        } else {
          console.error('No valid NFT data found');
          enqueueSnackbar('Failed to fetch NFT data', { variant: 'error' });
        }
      } else {
        console.error('No minted token IDs found');
        enqueueSnackbar('Failed to extract minted token IDs', { variant: 'error' });
      }
  
      enqueueSnackbar('NFT has been Minted Successfully', { variant: 'success' });
      setLoading(false);
      closeSnackbar('mint');
  
      // Refresh itemsSold after successful mint
      fetchItemsSold();
      
      // Update free mint status
      if (isFreeMint) {
        setIsClaimedFreeMint(true);
        setIsFreeMint(false);
      }
    } catch (e) {
      console.error('Error during minting:', e);
      setLoading(false);
      closeSnackbar('mint');
      enqueueSnackbar(e.message || 'Minting failed. Please try again', { variant: 'error' });
    }
  };

  const handleNextNFT = () => {
    if (currentNFTIndex < mintedNFTs.length - 1) {
      setCurrentNFTIndex(currentNFTIndex + 1);
    } else {
      setShowMintAnimation(false);
      setMintedNFTs([]);
      setCurrentNFTIndex(0);
    }
  };

  


  return (
    <>
      <MintSuccessAnimation
        isVisible={showMintAnimation}
        onClose={handleNextNFT}
        nftData={mintedNFTs[currentNFTIndex]}
        totalMinted={mintedNFTs.length}
        currentIndex={currentNFTIndex}
        onNext={handleNextNFT}
        price={saleParameters ? (isFreeMint ? '0' : saleParameters.price) : undefined} 
      />
      <BannerStyleWrapper className='bithu_v3_baner_sect' id='home'>
        <div className='container'>
          <div className='row align-items-center'>
            <div className='col-lg-6'>
              <div className='banner-image-area3'>
                <Particle />
                <img className='banner-image banner-image1' src={bannerThumb1} alt='bithu banner thumb' />
                <img className='banner-image banner-image2' src={bannerThumb2} alt='bithu banner thumb' />
                <img className='banner-image banner-image3' src={bannerThumb3} alt='bithu banner thumb' />
              </div>
            </div>
            <div className='col-lg-6'>
              <div className='banner-conent3'>
                <h4 className='banner-subtitle text-uppercase'>
                  Welcome to the <span className='red-color'>NEONAUTS</span>
                </h4>
                <h1 className='banner-title text-uppercase'>
                  Join the <span className='accent'>NEO X</span> <br /> Pioneers
                </h1>
                <SkeletonTheme baseColor="#202020" highlightColor="#444">
                  <div className='bithu_v3_timer'>
                    <h5 className='text-uppercase'>
                      {saleParameters && !isSaleStarted ? 'Sale Starts In:' : 'Total Minted:'}
                    </h5>
                    {saleParameters ? (
                      <div className='timer timer_1'>
                        {!isSaleStarted ? (
                          <Countdown
                            date={saleParameters.startTime * 1000}
                            renderer={renderer}
                            onComplete={() => setIsCountdownComplete(true)}
                          />
                        ) : (
                          <h5 className="root-react-component-countdown-timer mintedamount">
                            {itemsSold}<span>/</span>{Number(saleParameters.items)}
                          </h5>
                        )}
                      </div>
                    ) : (
                      <div className='timer timer_1'>
                        <h5 className="root-react-component-countdown-timer mintedamount">
                          <Skeleton width={100} height={40} duration={1.5} />
                        </h5>
                      </div>
                    )}
                  </div>

                  <div className='banner-count-inner d-flex align-items-center'>
                    <div className='banner-btn-area'>
                      <span className='input-number-decrement' onClick={() => count > 1 && setCount(count - 1)}>–</span>
                      <input className='input-number' type='text' value={count} onChange={(e) => setCount(Number(e.target.value))} />
                      <span className='input-number-increment' onClick={() => maxCount > count && setCount(count + 1)}>+</span>
                    </div>
                    <div className='bithu_v3_baner_buttons'>
                      {address ? (
                        <Button 
                        className={`${isFreeMint ? 'free-mint' : ''} ${loading || !isSaleStarted ? 'disabled' : ''}`} 
                        lg 
                        variant='mint' 
                        onClick={onMint} 
                          disabled={loading || !isSaleStarted }
                          style={isFreeMint ? {
                            background: 'linear-gradient(45deg, #ff0000, #ff7300, #fffb00, #48ff00, #00ffd5, #002bff, #7a00ff, #ff00c8, #ff0000)',
                            backgroundSize: '400% 400%',
                            animation: 'gradient 15s ease infinite',
                          } : {}}
                        >
                          {loading ? (
                            <img src='./assets/img/loading.svg' alt='' height='50' width='50' />
                          ) : !isSaleStarted ? (
                            'Sale Not Started'
                          ) : isFreeMint ? (
                            'FREE MINT'
                          ) : (
                            'Mint'
                          )}
                        </Button>
                      ) : (
                        <ConnectMenu />
                      )}
                    </div>
                  </div>
                  {saleParameters ? (
                    <>
                      <div className='bithu_v3_timer' style={{paddingTop:"5px"}}>
                        <h5 className='text-uppercase'>
                          Total Price: {(count * Number(saleParameters.price)).toFixed(1)} GAS
                        </h5>
                      </div>
                      <div className='bithu_v3_timer' style={{paddingTop:"5px"}}>
                        <h5 className='text-uppercase'>
                          Max Per Wallet: {Number(saleParameters.maxMintCap)}
                        </h5>
                      </div>
                    </>
                  ) : (
                    <>
                      <div className='bithu_v3_timer' style={{paddingTop:"5px"}}>
                        <h5 className='text-uppercase'>
                          <Skeleton width={200} height={24} duration={1.5} />
                        </h5>
                      </div>
                      <div className='bithu_v3_timer' style={{paddingTop:"5px"}}>
                        <h5 className='text-uppercase'>
                          <Skeleton width={200} height={24} duration={1.5} />
                        </h5>
                      </div>
                    </>
                  )}
                  {saleParameters ? (
                    <div className='banner-bottom-text text-uppercase'>
                      GET 1 FREE MINT INVITING {Number(saleParameters.referralThreshold)} FRIENDS
                    </div>
                  ) : (
                    <div className='banner-bottom-text text-uppercase'>
                      <Skeleton width={300} height={24} duration={1.5} />
                    </div>
                  )}
                  <div className="referral-section breadcrumb_form">
                    <form>
                      <input
                        type="text"
                        readOnly
                        value={address 
                          ? `${window.location.origin}?ref=${encodeAddress(address)}`
                          : "CONNECT YOUR WALLET"}
                      />
                      <button
                        type="button"
                        onClick={() => {
                          if (address) {
                            navigator.clipboard.writeText(`${window.location.origin}?ref=${encodeAddress(address)}`);
                            enqueueSnackbar('Referral link copied!', { variant: 'success' });
                          } else {
                            enqueueSnackbar('Please connect your wallet first', { variant: 'warning' });
                          }
                        }}
                      >
                        Copy
                      </button>
                    </form>
                    {address && (
                      <p className="referral-count">
                        Total Referrals: {referralCount} PIONEER(S)
                      </p>
                    )}
                  </div>
                </SkeletonTheme>
              </div>
            </div>
          </div>
        </div>
      </BannerStyleWrapper>
    </>
  );
};

export default Banner;