import { useEffect, useState } from 'react';
import { DEFAULT_NETWORK } from '../../../constants';
import { accountEllipsis } from '../../../helpers/get-account-ellipsis';
import { useWeb3Context } from '../../../hooks/web3';
import Button from '../../../common/button';

import Dropdown from 'react-bootstrap/Dropdown';

function ConnectMenu() {
  const {
    connect,
    disconnect,
    connected,
    web3,
    providerChainID,
    checkWrongNetwork,
    address,
  } = useWeb3Context();
  const [isConnected, setConnected] = useState(connected);

  let buttonText = 'Connect Wallet';
  let clickFunc: any = connect;

  useEffect(() => {
    setConnected(connected);
    if (connected && providerChainID !== DEFAULT_NETWORK) {
      checkWrongNetwork();
    }
  }, [web3, connected, providerChainID, checkWrongNetwork]);

  if (isConnected) {
    buttonText = accountEllipsis(address);
    clickFunc = disconnect;
  }

  if (isConnected && providerChainID !== DEFAULT_NETWORK) {
    buttonText = 'Wrong Network';
    clickFunc = checkWrongNetwork;
  }

  return (
    <>
      {address ? (
        <Dropdown>
          <Dropdown.Toggle
            variant='white'
            id='dropdown-basic'
            className='connect_btn'
          >
            {buttonText}
          </Dropdown.Toggle>

          <Dropdown.Menu>
            <Dropdown.Item href='/my-collection'>My Collection</Dropdown.Item>
            <Dropdown.Item href='# ' onClick={clickFunc}>
              {providerChainID !== DEFAULT_NETWORK ? 'Switch Network' : 'Disconnect'}
            </Dropdown.Item>
          </Dropdown.Menu>
        </Dropdown>
      ) : (
        <Button
          sm
          variant='hovered'
          className='connect_btn'
          onClick={clickFunc}
        >
          {buttonText}
        </Button>
      )}
    </>
  );
}

export default ConnectMenu;
