import { useEffect, useState } from "react";

type StateReturnType<T> = [T, React.Dispatch<React.SetStateAction<T>>];

export function useLocalStorage<T>(
  key: string,
  defaultValue: T
): StateReturnType<T> {
  const [state, setState] = useState<T>(defaultValue);

  useEffect(() => {
    const valueStr = localStorage.getItem(key);
    if (valueStr) {
      setState(JSON.parse(valueStr));
    }
  }, [key]);

  useEffect(() => {
    if (state !== null) {
      localStorage.setItem(key, JSON.stringify(state));
    } else {
      localStorage.removeItem(key);
    }
  }, [state, key]);

  return [state, setState];
}
