import { ethers } from "ethers";

const switchRequest = () => {
  return window.ethereum.request({
    method: 'wallet_switchEthereumChain',
    params: [{ chainId: `0x${parseInt(process.env.REACT_APP_CHAIN_ID || '0', 10).toString(16)}` }],
  });
};

const addChainRequest = () => {
  return window.ethereum.request({
    method: 'wallet_addEthereumChain',
    params: [
      {
        chainId: `0x${parseInt(process.env.REACT_APP_CHAIN_ID || '0', 10).toString(16)}`,
        chainName: process.env.REACT_APP_CHAIN_NAME,
        rpcUrls: [process.env.REACT_APP_RPC_URL],
        blockExplorerUrls: [process.env.REACT_APP_BLOCK_EXPLORER_URL],
        nativeCurrency: {
          name: process.env.REACT_APP_CURRENCY_NAME,
          symbol: process.env.REACT_APP_CURRENCY_SYMBOL,
          decimals: 18,
        },
      },
    ],
  });
};

export const switchNetwork = async () => {
  if (window.ethereum) {
    try {
      await switchRequest();
      // After successful switch, update the provider
      const newProvider = new ethers.providers.Web3Provider(window.ethereum, 'any');
      const newChainId = await newProvider.getNetwork().then(network => network.chainId);
      return { provider: newProvider, chainId: newChainId };
    } catch (error: any) {
      if (error.code === 4902) {
        try {
          await addChainRequest();
          await switchRequest();
          // After successful switch, update the provider
          const newProvider = new ethers.providers.Web3Provider(window.ethereum, 'any');
          const newChainId = await newProvider.getNetwork().then(network => network.chainId);
          return { provider: newProvider, chainId: newChainId };
        } catch (addError) {
          console.error("Failed to add the network:", addError);
          throw addError;
        }
      } else {
        console.error("Failed to switch network:", error);
        throw error;
      }
    }
  } else {
    console.error("No ethereum object found in window");
    throw new Error("No ethereum object found in window");
  }
};
